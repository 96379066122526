import React from "react";
import NavigationHelper from "../helpers/navigation";

export default function BlogTagLink(props) {
  let comma = "";
  if (props.needComma) {
    comma = <b>, </b>;
  }

  return (
    <>
      <a
        href={NavigationHelper.useGeneratePageURL(
          props.language,
          "blogtag",
          props.tagTitle.toLowerCase(),
        )}
      >
        {props.tagTitle}
        {comma}
      </a>
    </>
  );
}
