exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-area-legal-es-js": () => import("./../../../src/pages/area-legal.es.js" /* webpackChunkName: "component---src-pages-area-legal-es-js" */),
  "component---src-pages-contactenos-es-js": () => import("./../../../src/pages/contactenos.es.js" /* webpackChunkName: "component---src-pages-contactenos-es-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-es-js": () => import("./../../../src/pages/index.es.js" /* webpackChunkName: "component---src-pages-index-es-js" */),
  "component---src-pages-index-fr-js": () => import("./../../../src/pages/index.fr.js" /* webpackChunkName: "component---src-pages-index-fr-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-join-us-en-js": () => import("./../../../src/pages/join-us.en.js" /* webpackChunkName: "component---src-pages-join-us-en-js" */),
  "component---src-pages-legal-notices-en-js": () => import("./../../../src/pages/legal-notices.en.js" /* webpackChunkName: "component---src-pages-legal-notices-en-js" */),
  "component---src-pages-mentions-legales-fr-js": () => import("./../../../src/pages/mentions-legales.fr.js" /* webpackChunkName: "component---src-pages-mentions-legales-fr-js" */),
  "component---src-pages-nos-partenaires-amazon-web-services-fr-js": () => import("./../../../src/pages/nos-partenaires/amazon-web-services.fr.js" /* webpackChunkName: "component---src-pages-nos-partenaires-amazon-web-services-fr-js" */),
  "component---src-pages-nos-postes-developpeur-cloud-native-fr-js": () => import("./../../../src/pages/nos-postes/developpeur-cloud-native.fr.js" /* webpackChunkName: "component---src-pages-nos-postes-developpeur-cloud-native-fr-js" */),
  "component---src-pages-nos-services-fr-js": () => import("./../../../src/pages/nos-services.fr.js" /* webpackChunkName: "component---src-pages-nos-services-fr-js" */),
  "component---src-pages-nos-solutions-audit-cloud-roadmap-fr-js": () => import("./../../../src/pages/nos-solutions/audit-cloud-roadmap.fr.js" /* webpackChunkName: "component---src-pages-nos-solutions-audit-cloud-roadmap-fr-js" */),
  "component---src-pages-nos-solutions-devops-team-as-a-service-fr-js": () => import("./../../../src/pages/nos-solutions/devops-team-as-a-service.fr.js" /* webpackChunkName: "component---src-pages-nos-solutions-devops-team-as-a-service-fr-js" */),
  "component---src-pages-notre-vision-fr-js": () => import("./../../../src/pages/notre-vision.fr.js" /* webpackChunkName: "component---src-pages-notre-vision-fr-js" */),
  "component---src-pages-nous-rejoindre-fr-js": () => import("./../../../src/pages/nous-rejoindre.fr.js" /* webpackChunkName: "component---src-pages-nous-rejoindre-fr-js" */),
  "component---src-pages-nuestra-vision-es-js": () => import("./../../../src/pages/nuestra-vision.es.js" /* webpackChunkName: "component---src-pages-nuestra-vision-es-js" */),
  "component---src-pages-nuestras-ofertas-desarrollador-cloud-native-es-js": () => import("./../../../src/pages/nuestras-ofertas/desarrollador-cloud-native.es.js" /* webpackChunkName: "component---src-pages-nuestras-ofertas-desarrollador-cloud-native-es-js" */),
  "component---src-pages-nuestras-soluciones-devops-team-as-a-service-es-js": () => import("./../../../src/pages/nuestras-soluciones/devops-team-as-a-service.es.js" /* webpackChunkName: "component---src-pages-nuestras-soluciones-devops-team-as-a-service-es-js" */),
  "component---src-pages-nuestros-servicios-es-js": () => import("./../../../src/pages/nuestros-servicios.es.js" /* webpackChunkName: "component---src-pages-nuestros-servicios-es-js" */),
  "component---src-pages-nuestros-socios-amazon-web-services-es-js": () => import("./../../../src/pages/nuestros-socios/amazon-web-services.es.js" /* webpackChunkName: "component---src-pages-nuestros-socios-amazon-web-services-es-js" */),
  "component---src-pages-our-partners-amazon-web-services-en-js": () => import("./../../../src/pages/our-partners/amazon-web-services.en.js" /* webpackChunkName: "component---src-pages-our-partners-amazon-web-services-en-js" */),
  "component---src-pages-our-positions-cloud-native-developer-en-js": () => import("./../../../src/pages/our-positions/cloud-native-developer.en.js" /* webpackChunkName: "component---src-pages-our-positions-cloud-native-developer-en-js" */),
  "component---src-pages-our-services-en-js": () => import("./../../../src/pages/our-services.en.js" /* webpackChunkName: "component---src-pages-our-services-en-js" */),
  "component---src-pages-our-solutions-devops-team-as-a-service-en-js": () => import("./../../../src/pages/our-solutions/devops-team-as-a-service.en.js" /* webpackChunkName: "component---src-pages-our-solutions-devops-team-as-a-service-en-js" */),
  "component---src-pages-our-vision-en-js": () => import("./../../../src/pages/our-vision.en.js" /* webpackChunkName: "component---src-pages-our-vision-en-js" */),
  "component---src-pages-temoignages-javelo-fr-js": () => import("./../../../src/pages/temoignages/javelo.fr.js" /* webpackChunkName: "component---src-pages-temoignages-javelo-fr-js" */),
  "component---src-pages-temoignages-upfactor-fr-js": () => import("./../../../src/pages/temoignages/upfactor.fr.js" /* webpackChunkName: "component---src-pages-temoignages-upfactor-fr-js" */),
  "component---src-pages-testimonials-javelo-en-js": () => import("./../../../src/pages/testimonials/javelo.en.js" /* webpackChunkName: "component---src-pages-testimonials-javelo-en-js" */),
  "component---src-pages-testimonials-upfactor-en-js": () => import("./../../../src/pages/testimonials/upfactor.en.js" /* webpackChunkName: "component---src-pages-testimonials-upfactor-en-js" */),
  "component---src-pages-testimonios-javelo-es-js": () => import("./../../../src/pages/testimonios/javelo.es.js" /* webpackChunkName: "component---src-pages-testimonios-javelo-es-js" */),
  "component---src-pages-testimonios-upfactor-es-js": () => import("./../../../src/pages/testimonios/upfactor.es.js" /* webpackChunkName: "component---src-pages-testimonios-upfactor-es-js" */),
  "component---src-pages-vos-temoignages-fr-js": () => import("./../../../src/pages/vos-temoignages.fr.js" /* webpackChunkName: "component---src-pages-vos-temoignages-fr-js" */),
  "component---src-pages-vuestros-testimonios-es-js": () => import("./../../../src/pages/vuestros-testimonios.es.js" /* webpackChunkName: "component---src-pages-vuestros-testimonios-es-js" */),
  "component---src-pages-your-testimonials-en-js": () => import("./../../../src/pages/your-testimonials.en.js" /* webpackChunkName: "component---src-pages-your-testimonials-en-js" */),
  "component---src-templates-blog-category-fr-js": () => import("./../../../src/templates/blog-category.fr.js" /* webpackChunkName: "component---src-templates-blog-category-fr-js" */),
  "component---src-templates-blog-fr-js": () => import("./../../../src/templates/blog.fr.js" /* webpackChunkName: "component---src-templates-blog-fr-js" */),
  "component---src-templates-blog-page-js": () => import("./../../../src/templates/blog-page.js" /* webpackChunkName: "component---src-templates-blog-page-js" */),
  "component---src-templates-blog-tag-fr-js": () => import("./../../../src/templates/blog-tag.fr.js" /* webpackChunkName: "component---src-templates-blog-tag-fr-js" */),
  "component---src-templates-product-page-fr-js": () => import("./../../../src/templates/product-page.fr.js" /* webpackChunkName: "component---src-templates-product-page-fr-js" */)
}

