import React from "react";
import { Trans } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import ContactApi from "../api/contact";

export class JoinUsSingleContactForm extends React.Component {
  constructor(props) {
    super(props);

    // The source of the contact
    this.request_type = "job";
    this.request_source_item = props.contact_source;

    // Classes for form fields
    this.defaultFormClasses = {
      fname: "form-control",
      lname: "form-control",
      number: "form-control",
      email: "form-control",
      message: "form-control",
      agree: "form-check-input",
      agree_txt: "form-check-label",
    };

    this.stateFieldsClassProps = {
      fname: "field_class_fname",
      lname: "field_class_lname",
      number: "field_class_number",
      email: "field_class_email",
      message: "field_class_message",
      agree: "field_class_agree",
      agree_txt: "field_class_agree_txt",
    };

    this.state = {
      lname: "",
      fname: "",
      email: "",
      number: "",
      message: "",
      agree: false,
      [this.stateFieldsClassProps["fname"]]: this.defaultFormClasses["fname"],
      [this.stateFieldsClassProps["lname"]]: this.defaultFormClasses["lname"],
      [this.stateFieldsClassProps["number"]]: this.defaultFormClasses["number"],
      [this.stateFieldsClassProps["email"]]: this.defaultFormClasses["email"],
      [this.stateFieldsClassProps["message"]]:
        this.defaultFormClasses["message"],
      [this.stateFieldsClassProps["agree"]]: this.defaultFormClasses["agree"],
      [this.stateFieldsClassProps["agree_txt"]]:
        this.defaultFormClasses["agree_txt"],
      formClasses: { ...this.defaultFormClasses },
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    // Form fields i18n properties
    this.name_label = props.name_label;
    this.name_ph = props.name_ph;
    this.fname_label = props.fname_label;
    this.fname_ph = props.fname_ph;
    this.phone_label = props.phone_label;
    this.phone_ph = props.phone_ph;
    this.email_label = props.email_label;
    this.email_ph = props.email_ph;
    this.message_label = props.message_label;
    this.message_ph = props.message_ph;
    this.terms_msg_p1 = props.terms_msg_p1;
    this.terms_msg_p2 = props.terms_msg_p2;
    this.send_btn = props.send_btn;
    this.required_field_msg = props.form_required_field_msg;

    // notifications messages
    this.notif_messages = {
      at_least_one_error: props.notif_at_least_one_error_msg,
      sending_form_info: props.notif_sending_form_msg,
      message_sent_success: props.success_message_sent_msg,
    };

    // backend field validation error messages
    this.backend_validation_unkown_error = props.notif_unknown_error_msg;
    this.backend_validation_errors = {
      email_wrong_value: props.notif_email_validation_error_msg, // "The email address is wrong!",
      lname_wrong_value: props.notif_lname_validation_error_msg, // "Your lastname must be a valid string",
      fname_wrong_value: props.notif_fname_validation_error_msg, // "Your firstname must be a valid string",
      company_wrong_value: props.notif_company_validation_error_msg, // "The value for your company's name seems wrong",
      number_wrong_value: props.notif_number_validation_error_msg, // "Is this phone number really valid ?",
      message_wrong_value: props.notif_message_validation_error_msg, // "Don't be shy please send a message between 5 and 500 characters!"
    };
  }

  resetAllFields() {
    const blankValues = {
      lname: "",
      fname: "",
      email: "",
      number: "",
      message: "",
      agree: false,
    };
    this.setState(blankValues, () => {
      return true;
    });
  }

  resetErrorFields() {
    const keysList = Object.keys(this.stateFieldsClassProps);
    for (const idx in keysList) {
      const key = keysList[idx];
      const update = this.defaultFormClasses[key];
      this.setState({ [this.stateFieldsClassProps[key]]: update });
    }
  }

  handleChange(event) {
    let update = {};
    this.resetErrorFields();
    switch (event.target.name) {
      case "lname":
        update[event.target.name] = event.target.value;
        this.setState(update, this.checkFormLastName);
        break;
      case "fname":
        update[event.target.name] = event.target.value;
        this.setState(update, this.checkFormFirstName);
        break;
      case "number":
        update[event.target.name] = event.target.value;
        this.setState(update, this.checkForNumber);
        break;
      case "email":
        update[event.target.name] = event.target.value;
        this.setState(update, this.checkForEmail);
        break;
      case "message":
        update[event.target.name] = event.target.value;
        this.setState(update, this.checkForMessage);
        break;
      case "agree":
        update[event.target.name] = event.target.checked;
        this.setState(update, this.checkForAgree);
        break;
    }
  }

  checkFormFirstName() {
    const bValidation = this.state.fname.trim().length > 0;

    return bValidation;
  }

  checkFormLastName() {
    const bValidation = this.state.lname.trim().length > 0;
    if (!bValidation) {
      const update = `${this.defaultFormClasses["lname"]} error`;
      this.setState({ field_class_lname: update });
    }

    return bValidation;
  }

  checkForNumber() {
    const bValidation = this.state.number.trim().length > 0;
    return bValidation;
  }

  checkForEmail() {
    const bValidation = this.state.email.trim().length > 0;
    if (!bValidation) {
      const update = `${this.defaultFormClasses["email"]} error`;
      this.setState({ field_class_email: update });
    }
    return bValidation;
  }

  checkForMessage() {
    const bValidation = this.state.message.trim().length > 0;
    if (!bValidation) {
      const update = `${this.defaultFormClasses["message"]} error`;
      this.setState({ field_class_message: update });
    }
    return bValidation;
  }

  checkForAgree() {
    const bValidation = this.state.agree;
    if (!bValidation) {
      const update = `${this.defaultFormClasses["agree"]} error`;
      this.setState({ field_class_agree: update });

      const txt_update = `${this.defaultFormClasses["agree_txt"]} error`;
      this.setState({ field_class_agree_txt: txt_update });
    }
    return bValidation;
  }

  checkFormValues() {
    const validation =
      this.checkFormLastName() &
      this.checkForEmail() &
      this.checkForMessage() &
      this.checkForAgree();

    return Boolean(validation);
  }

  triggerErrorOnField(field) {
    const update = `${this.defaultFormClasses[field]} error`;
    this.setState({ [this.stateFieldsClassProps[field]]: update });
  }

  async handleSubmit(event) {
    event.preventDefault();
    if (this.checkFormValues()) {
      toast.info(this.notif_messages["sending_form_info"]);
      try {
        const response = await ContactApi.post("/joinus/", {
          email: this.state.email,
          lname: this.state.lname,
          fname: this.state.fname,
          number: this.state.number,
          company: "Future A1 Cloud ?",
          message: this.state.message,
          reqtype: this.request_type,
          reqsource: this.request_source_item,
        });
        if (response.data.success) {
          this.resetErrorFields();
          this.resetAllFields();
          toast.success(this.notif_messages["message_sent_success"]);
        } else {
          toast.error(this.notif_messages["at_least_one_error"]);

          // Collecting errors from the backend
          if (response.data.errors.length > 0) {
            for (const backend_error in response.data.errors) {
              this.triggerErrorOnField(
                response.data.errors[backend_error].param,
              );

              // Do we know the error ?
              const known_backend_errors = Object.keys(
                this.backend_validation_errors,
              );
              if (
                known_backend_errors.includes(
                  response.data.errors[backend_error].msg,
                )
              ) {
                const customFieldErrMsg =
                  this.backend_validation_errors[
                    response.data.errors[backend_error].msg
                  ];
                toast.error(customFieldErrMsg);
              } else {
                toast.error(this.backend_validation_unkown_error);
              }
            }
          }
        }
      } catch (e) {
        toast.error(this.backend_validation_unkown_error);
      }
    } else {
      toast.error(this.notif_messages["at_least_one_error"]);
    }
  }

  render() {
    return (
      <>
        <form
          className="inquery_block"
          noValidate="novalidate"
          onSubmit={this.handleSubmit}
        >
          <h3>
            <Trans i18nKey="main:joinus_contact_form_title_html">
              Tu te reconnais là-dedans ? <br />
              Contacte-nous!
            </Trans>
          </h3>
          <div className="form-group">
            <label className="hide" htmlFor="exampleInputFname">
              prénom
            </label>
            <input
              type="text"
              className={this.state[this.stateFieldsClassProps["fname"]]}
              name="fname"
              id="exampleInputFname"
              value={this.state.fname}
              placeholder={this.fname_ph}
              onChange={this.handleChange}
            />
          </div>
          <div className="form-group">
            <label className="hide" htmlFor="exampleInputLname">
              nom
            </label>
            <input
              type="text"
              className={this.state[this.stateFieldsClassProps["lname"]]}
              name="lname"
              id="exampleInputLname"
              value={this.state.lname}
              placeholder={this.name_ph}
              onChange={this.handleChange}
            />
          </div>
          <div className="form-group">
            <label className="hide" htmlFor="exampleInputNumber">
              Téléphone
            </label>
            <input
              type="tel"
              className={this.state[this.stateFieldsClassProps["number"]]}
              id="exampleInputNumber"
              name="number"
              value={this.state.number}
              placeholder={this.phone_ph}
              onChange={this.handleChange}
            />
          </div>
          <div className="form-group">
            <label className="hide" htmlFor="exampleInputEmail1">
              Mail
            </label>
            <input
              type="email"
              className={this.state[this.stateFieldsClassProps["email"]]}
              id="exampleInputEmail1"
              name="email"
              value={this.state.email}
              placeholder={this.email_ph}
              onChange={this.handleChange}
            />
          </div>
          {/* <div className="form-group">
            <div className="input_file_div">
              <label className="hide" htmlFor="exampleInputEmail1">
                CURRICULUM VITAE
              </label>
              <input
                type="file"
                id="real-file"
                hidden="hidden"
                accept="application/pdf"
              />
              <span id="custom-text">CURRICULUM VITAE</span>
              <div className="upload_links">
                <button type="button" id="custom-button">
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="cloud-download-alt"
                    className="svg-inline--fa fa-cloud-download-alt fa-w-20"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 640 512"
                  >
                    <path
                      fill="currentColor"
                      d="M537.6 226.6c4.1-10.7 6.4-22.4 6.4-34.6 0-53-43-96-96-96-19.7 0-38.1 6-53.3 16.2C367 64.2 315.3 32 256 32c-88.4 0-160 71.6-160 160 0 2.7.1 5.4.2 8.1C40.2 219.8 0 273.2 0 336c0 79.5 64.5 144 144 144h368c70.7 0 128-57.3 128-128 0-61.9-44-113.6-102.4-125.4zm-132.9 88.7L299.3 420.7c-6.2 6.2-16.4 6.2-22.6 0L171.3 315.3c-10.1-10.1-2.9-27.3 11.3-27.3H248V176c0-8.8 7.2-16 16-16h48c8.8 0 16 7.2 16 16v112h65.4c14.2 0 21.4 17.2 11.3 27.3z"
                    />
                  </svg>
                </button>
                <button type="button" id="custom-button">
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="folder-open"
                    className="svg-inline--fa fa-folder-open fa-w-18"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 576 512"
                  >
                    <path
                      fill="currentColor"
                      d="M572.694 292.093L500.27 416.248A63.997 63.997 0 0 1 444.989 448H45.025c-18.523 0-30.064-20.093-20.731-36.093l72.424-124.155A64 64 0 0 1 152 256h399.964c18.523 0 30.064 20.093 20.73 36.093zM152 224h328v-48c0-26.51-21.49-48-48-48H272l-64-64H48C21.49 64 0 85.49 0 112v278.046l69.077-118.418C86.214 242.25 117.989 224 152 224z"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div> */}
          {/* <div className="form-group">
            <div className="input_file_div">
              <label className="hide" htmlFor="exampleInputEmail1">
                LETTRE DE MOTIVATION
              </label>
              <input
                type="file"
                id="real-file2"
                hidden="hidden"
                accept="application/pdf"
              />
              <span id="custom-text2">LETTRE DE MOTIVATION</span>
              <div className="upload_links">
                <button type="button" id="custom-button2">
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="cloud-download-alt"
                    className="svg-inline--fa fa-cloud-download-alt fa-w-20"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 640 512"
                  >
                    <path
                      fill="currentColor"
                      d="M537.6 226.6c4.1-10.7 6.4-22.4 6.4-34.6 0-53-43-96-96-96-19.7 0-38.1 6-53.3 16.2C367 64.2 315.3 32 256 32c-88.4 0-160 71.6-160 160 0 2.7.1 5.4.2 8.1C40.2 219.8 0 273.2 0 336c0 79.5 64.5 144 144 144h368c70.7 0 128-57.3 128-128 0-61.9-44-113.6-102.4-125.4zm-132.9 88.7L299.3 420.7c-6.2 6.2-16.4 6.2-22.6 0L171.3 315.3c-10.1-10.1-2.9-27.3 11.3-27.3H248V176c0-8.8 7.2-16 16-16h48c8.8 0 16 7.2 16 16v112h65.4c14.2 0 21.4 17.2 11.3 27.3z"
                    />
                  </svg>
                </button>
                <button type="button" id="custom-button4">
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="folder-open"
                    className="svg-inline--fa fa-folder-open fa-w-18"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 576 512"
                  >
                    <path
                      fill="currentColor"
                      d="M572.694 292.093L500.27 416.248A63.997 63.997 0 0 1 444.989 448H45.025c-18.523 0-30.064-20.093-20.731-36.093l72.424-124.155A64 64 0 0 1 152 256h399.964c18.523 0 30.064 20.093 20.73 36.093zM152 224h328v-48c0-26.51-21.49-48-48-48H272l-64-64H48C21.49 64 0 85.49 0 112v278.046l69.077-118.418C86.214 242.25 117.989 224 152 224z"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div> */}
          <div className="form-group">
            <label className="hide" htmlFor="exampleFormControlmessage">
              MESSAGE
            </label>
            <textarea
              className={this.state[this.stateFieldsClassProps["message"]]}
              id="FormControlmessage"
              name="message"
              rows={5}
              placeholder={this.message_ph}
              // defaultValue={this.message_ph}
              value={this.state.message}
              onChange={this.handleChange}
            />
          </div>
          <div className="form-check">
            <span className={this.state[this.stateFieldsClassProps["agree"]]}>
              <input
                type="checkbox"
                id="exampleCheck1"
                name="agree"
                checked={this.state.agree}
                onChange={this.handleChange}
              />
              <span />
            </span>
            <label
              className={this.state.field_class_agree_txt}
              htmlFor="exampleCheck1"
            >
              {this.terms_msg_p1} {this.terms_msg_p2}
            </label>
          </div>
          <div className="form-group">
            <input
              type="submit"
              id="subbtn"
              className="btn"
              defaultValue={this.send_btn}
              value={this.send_btn}
            />
          </div>
        </form>
        <ToastContainer />
      </>
    );
  }
}
