import * as React from "react";
import { useState, useEffect } from "react";
import { graphql } from "gatsby";
import i18n from "../components/i18n";
import { withTranslation, useTranslation } from "react-i18next";
import Layout from "../components/layout";
import ServiceSingleBoxCard from "../components/service_single_box_card";
import HubspotCta from "../components/hubspot_cta";

import { ServiceContactForm } from "../components/form_service_contact";
import SocialConfData from "../conf/social.json";
import Seo from "../components/seo";
import ClientSlider from "../components/client_slider";

// Images import
import img_service_header from "../images/service-header.jpg";
import img_service_devops_release_engin from "../images/service_details.png";
import img_service_es_migracion_a_la_nube from "../images/service_cloud_migration.png";
import img_service_en_cloud_migration from "../images/service_cloud_migration.png";
import img_service_fr_cloud_migration from "../images/service_cloud_migration.png";
import img_service_fr_run_aws from "../images/service_run_aws.png";
import img_service_en_run_aws from "../images/service_run_aws.png";
import img_service_es_run_aws from "../images/service_run_aws.png";
// import img_service_audit_cloud_roadmap from "../images/service_cloud_roadmap.png"
import img_service_default from "../images/service_cloud_roadmap.png";

// Testimonial
import NavigationHelper from "../helpers/navigation";

// Retrieves the right image based on the product and the language
const productImagesMap = {
  "fr_release-engineering": img_service_devops_release_engin,
  "fr_migration-cloud": img_service_fr_cloud_migration,
  "fr_run-infrastructure-aws": img_service_fr_run_aws,
  "en_release-engineering": img_service_devops_release_engin,
  "en_cloud-migration": img_service_en_cloud_migration,
  "en_run-aws-infrastructure": img_service_en_run_aws,
  "es_release-engineering": img_service_devops_release_engin,
  "es_migracion-a-la-nube": img_service_es_migracion_a_la_nube,
  "es_mantenimiento-de-infraestructura-aws": img_service_es_run_aws,
};

// markup
const ProductPage = (props) => {
  const pageGenLang = props.data.allServicesJson.edges[0].node.language;
  i18n.language = pageGenLang;

  const [activeLanguage, setActiveLanguage] = useState();
  useEffect(() => {
    setActiveLanguage(i18n.language);
  }, [setActiveLanguage]);

  const { t } = useTranslation(["main", "forms"]);
  const ExtContactForm = withTranslation("forms")(ServiceContactForm);
  const LocalizedClientSlider = withTranslation("main")(ClientSlider);

  let productData;
  props.data.allServicesJson.edges.forEach((edge) => {
    if (edge.node.language == i18n.language) {
      productData = edge.node;
    }
  });

  // Navigation info
  const pageInfo = {
    key: `solutions`, // This allows the langswitcher to add a JS link instead of a regular one
    item_key: productData.service_url_key, // This allows the langswitcher to add a JS link instead of a regular one
    lang: i18n.language,
  };

  // General product information
  const company_name = productData.company_name || "A1 Cloud";

  //For the social share buttons
  const title = productData.seo.title;
  const url = props.location.href;
  const twitterHandle = SocialConfData.ids.twitter;
  const tags = productData.seo.keywords;

  return (
    <Layout location={props.location} pageInfo={pageInfo}>
      <Seo
        description={productData.seo.description}
        title={productData.seo.title}
        keywords={productData.seo.keywords}
        url={props.location.href}
        image_url={`${props.location.origin}/a1_cloud_services_in_a_box.webp`}
      />

      {/* breadcumbs */}
      <div className="site-breadcumbs">
        <div className="container">
          <small>
            <a
              href={NavigationHelper.useGeneratePageURL(activeLanguage, "home")}
              title={t("main:global_header_home_menu-item-seo-title_txt")}
            >
              A1:
            </a>{" "}
            //{" "}
            <a
              href={NavigationHelper.useGeneratePageURL(
                activeLanguage,
                "services",
              )}
              title={t("main:global_header_services_menu-item-seo-title_txt")}
            >
              {t("main:services_header_breadcrumb_title")}
            </a>{" "}
            /{" "}
            <a
              href={NavigationHelper.useGeneratePageURL(
                activeLanguage,
                "services",
              )}
            >
              {productData.domain_name}
            </a>{" "}
            / {productData.breadcrumb_title}
          </small>
        </div>
      </div>
      {/* end breadcumbs */}

      <div
        className="s_detail service_header"
        style={{ backgroundImage: `url(${img_service_header})` }}
      >
        <div className="container">
          <div className="title_block">
            <h1 className="nos-service">
              <span> {productData.domain_name.toString().toLowerCase()} :</span>{" "}
              {productData.title.toString()}
            </h1>
          </div>
          <div className="row">
            <div className="col-md-5">
              <div className="book_img">
                <img
                  className="book_img"
                  src={
                    productImagesMap[`${productData.slug}`] ||
                    img_service_default
                  }
                  alt={t("main:service-global-image-alt-txt", {
                    service_name: productData.title,
                    company_name: company_name,
                  })}
                />
              </div>
            </div>
            <div className="col-md-7">
              <div className="block_text desktop_view">
                <h2 className="h2_text">
                  <strong>{productData.tagline_p1}</strong>
                  <br />
                  {productData.tagline_p2 && (
                    <>
                      <strong>{productData.tagline_p2}</strong>
                      <br />
                    </>
                  )}
                  {productData.tagline_p3 && (
                    <>
                      <strong>{productData.tagline_p3}</strong>
                      <br />
                    </>
                  )}
                </h2>
                <div className="p_text">
                  <p>{productData.tagline_details}</p>
                </div>
              </div>
              <div className="block_text mobile_view">
                <h2 className="h2_text">
                  <strong>{productData.tagline_p1}</strong>
                  <br />
                  {productData.tagline_p2 && (
                    <>
                      <strong>{productData.tagline_p2}</strong>
                      <br />
                    </>
                  )}
                  {productData.tagline_p3 && (
                    <>
                      <strong>{productData.tagline_p3}</strong>
                      <br />
                    </>
                  )}
                </h2>
                <div className="p_text">
                  <p>{productData.tagline_details}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* service single header end  */}
      {/* middle section  */}
      <div className="service_detail s_detail">
        <div className="container">
          <div className="block_box">
            <ServiceSingleBoxCard
              // isNew={productData.is_new}
              category={productData.domain_slug}
              category_label={productData.domain_name.toString().toLowerCase()}
              service_name={productData.title}
              service_name_fragment_1={productData.title_p1}
              service_name_fragment_2={productData.title_p2}
              service_name_fragment_3={productData.title_p3}
              service_name_fragment_4={productData.title_p4}
              link_seo_title={t("main:global_main_know-more_link")}
              share_label={t("main:global_main_share_label")}
              service_description={productData.description_details}
              share_title={title}
              share_url={url}
              share_twitter_handle={twitterHandle}
              share_twitter_tags={tags}
            />
          </div>
          <div className="middle_content">
            <h2>
              <span>{productData.domain_name}</span> {productData.title}
            </h2>

            <div
              dangerouslySetInnerHTML={{
                __html: productData.description_intro,
              }}
            ></div>

            <h4>{productData.first_section_title}</h4>

            <div
              dangerouslySetInnerHTML={{
                __html: productData.first_section_content,
              }}
            ></div>

            <h4>{productData.second_section_title}</h4>

            <div
              dangerouslySetInnerHTML={{
                __html: productData.second_section_content,
              }}
            ></div>

            {/* </Trans> */}

            <div className="button-banner">
              <HubspotCta
                title={productData.cta_title}
                btnTxt={productData.cta}
                ctaId="b3e6a794-c4e2-45c6-a1fa-9d7b680e85dc"
                url="https://hubspot-cta-redirect-eu1-prod.s3.amazonaws.com/cta/redirect/26582871/a10c4452-6fd6-4ae8-bb31-f89bedbfc14e"
              />
            </div>
          </div>
          <div className="form_block" id="contact-form">
            <h3
              dangerouslySetInnerHTML={{ __html: productData.form_title }}
            ></h3>
            <ExtContactForm
              contact_source={`${t(
                "main:service-description_cloud-roadmap_meta_page_title",
              )}`}
              name_label={t("forms:home_contact_name_label")}
              name_ph={t("forms:home_contact_name_placeholder")}
              fname_label={t("forms:home_contact_firstname_label")}
              fname_ph={t("forms:home_contact_firstname_placeholder")}
              company_label={t("forms:home_contact_company_label")}
              company_ph={t("forms:home_contact_company_placeholder")}
              phone_label={t("forms:home_contact_phone_label")}
              phone_ph={t("forms:home_contact_phone_placeholder")}
              email_label={t("forms:home_contact_email_label")}
              email_ph={t("forms:home_contact_email_placeholder")}
              message_label={t("forms:home_contact_message_label")}
              message_ph={t("forms:services_contact_message_placeholder")}
              terms_msg_p1={t("forms:home_contact_terms_p1")}
              terms_msg_p2={t("forms:home_contact_terms_p2")}
              send_btn={t("forms:home_contact_send_btn")}
              notif_at_least_one_error_msg={t(
                "forms:global_error_at_least_one_msg",
              )}
              notif_sending_form_msg={t("forms:global_info_sending_form_msg")}
              success_message_sent_msg={t(
                "forms:global_success_message_sent_msg",
              )}
              notif_unknown_error_msg={t("forms:global_error_unknown_msg")}
              notif_email_validation_error_msg={t(
                "forms:notif_email_validation_error_msg",
              )}
              notif_lname_validation_error_msg={t(
                "forms:notif_lname_validation_error_msg",
              )}
              notif_fname_validation_error_msg={t(
                "forms:notif_fname_validation_error_msg",
              )}
              notif_company_validation_error_msg={t(
                "forms:notif_company_validation_error_msg",
              )}
              notif_number_validation_error_msg={t(
                "forms:notif_number_validation_error_msg",
              )}
              notif_message_validation_error_msg={t(
                "forms:notif_message_validation_error_msg",
              )}
              form_required_field_msg={t(
                "forms:global_label_required_field_msg",
              )}
            />
          </div>
        </div>
      </div>
      {/* end middle section */}

      <div className="services-reference" id="clients">
        <LocalizedClientSlider />
      </div>

      {/* main content end */}
    </Layout>
  );
};

export const query = graphql`
  query ProductQuery($productSlug: String!) {
    allServicesJson(filter: { slug: { eq: $productSlug } }) {
      edges {
        node {
          language
          company_name
          title
          title_p1
          title_p2
          title_p3
          title_p4
          breadcrumb_title
          domain_slug
          slug
          service_url_key
          tagline_p1
          tagline_p2
          tagline_p3
          tagline_details
          description_intro
          description_details
          first_section_title
          first_section_content
          second_section_title
          second_section_content
          cta
          cta_title
          seo {
            title
            description
            keywords
          }
          form_title
          domain_name
          is_new
        }
      }
    }
  }
`;

export default ProductPage;
