import * as React from "react";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import i18n from "../components/i18n";
import { useTranslation } from "react-i18next";
import Layout from "../components/layout";
import NavigationHelper from "../helpers/navigation";
import Seo from "../components/seo";
import ClientSlider from "../components/client_slider";
import * as _ from "lodash";
// import { ToastContainer, toast } from "react-toastify";

import SocialConfData from "../conf/social.json";

/** Blog dependencies */
import BlogHelper from "../helpers/blogposts";
import BlogVideosInfo from "../blog/videos.json";
import BlogCategories from "../blog/categories.json";
import BlogItemSidebar from "../components/blog_item_sidebar";
import ReactJWPlayerContainer from "../components/react_jwplayer";
import BlogTags from "../blog/tags.json";

/** Images import */
import img_slider1 from "../images/a1_blog_header_banner_bg.webp";

// markup
const BlogPage = (props) => {
  const [activeLanguage, setActiveLanguage] = useState();
  useEffect(() => {
    setActiveLanguage(i18n.language);
  }, [setActiveLanguage]);

  const { t } = useTranslation(["main", "forms"]);

  const BlogPostData = props.data.allArticlesJson.edges[0].node;
  const cdnBaseUrl = process.env.GATSBY_CDN_URL;
  const videoSubtitlesEnabledLanguages = ["fr"];

  // Navigation info
  const pageInfo = {
    key: "blog",
    lang: i18n.language,
  };

  const title = BlogPostData.htmlTitle;
  const metaDesc = BlogPostData.metaDescription;
  const publishDate = new Date(BlogPostData.publishDate);
  const category = BlogCategories[BlogPostData.contentGroupId]["code"];
  const categoryTitle =
    BlogCategories[BlogPostData.contentGroupId][activeLanguage] || "";
  const url = props.location.href;
  const twitterHandle = SocialConfData.ids.twitter;

  let keywords = new Array();
  BlogPostData.tagIds.map((tagId) => {
    try {
      const tagInfo = _.find(BlogTags, function (o) {
        return o.id == tagId;
      });
      keywords.push(tagInfo.name);
    } catch (err) {
      console.log(`Not able to push the tag ${tagId} to keywords`);
    }
  });

  return (
    <Layout location={props.location} pageInfo={pageInfo}>
      <Helmet
        bodyAttributes={{
          class: "testi-monial-main-page blog",
        }}
      >
        <title>{_.unescape(title)}</title>
      </Helmet>

      <Seo
        description={_.unescape(metaDesc)}
        title={_.unescape(title)}
        keywords={keywords}
        url={props.location.href}
        image_url={`${BlogHelper.getCDNImgPath(BlogPostData.featuredImage)}`}
      />

      <div className="main-content">
        {/* breadcumbs */}
        <div className="site-breadcumbs">
          <div className="container">
            <small>
              <a
                href={NavigationHelper.useGeneratePageURL(
                  activeLanguage,
                  "home",
                )}
                title={t("main:global_header_home_menu-item-seo-title_txt")}
              >
                A1:
              </a>{" "}
              //{" "}
              <a
                href={NavigationHelper.useGeneratePageURL(
                  activeLanguage,
                  "blog",
                )}
                title={t("main:global_header_blog_menu-item-seo-title_txt")}
              >
                {t("main:blog_header_breadcrumb_title")}
              </a>{" "}
              /{" "}
              <a
                href={NavigationHelper.useGeneratePageURL(
                  activeLanguage,
                  "blogcategory",
                  categoryTitle.toLowerCase(),
                )}
                title={t(
                  "main:global_header_blog_menu-item-category-seo-title_txt",
                  { categoryName: categoryTitle },
                )}
              >
                {categoryTitle.toLowerCase()}
              </a>{" "}
              / {title}
            </small>
          </div>
        </div>
        {/* end breadcumbs */}

        <div
          className="service_header"
          style={{ backgroundImage: `url(${img_slider1})` }}
        >
          <div className="container">
            <div className="title_block">
              <h1 className="nos-service"> {title}</h1>
            </div>
          </div>
        </div>

        <div className="service_detail nosrej_service blog-details-main-content">
          <div className=" text-center desktop_view">
            <h2>{BlogPostData.htmlTitle}</h2>
          </div>

          <div className="container">
            <BlogItemSidebar
              slug={BlogPostData.slug}
              htmlTitle={BlogPostData.htmlTitle}
              sidebarNavTitle={t("main:blog_list_sidebar_navigation_title")}
              sidebarCategoriesTitle={t(
                "main:blog_list_sidebar_categories_title",
              )}
              sidebarTagsTitle={t("main:blog_list_sidebar_tags_title")}
              shareTitle={t("main:global_main_share_label")}
              language={i18n.language}
              featuredImage={BlogPostData.featuredImage}
              featuredImageAltText={BlogPostData.featuredImageAltText}
              category={category}
              categoryTitle={categoryTitle}
              tags={BlogPostData.tagIds}
              date={publishDate.toLocaleDateString()}
              share_twitter_handle={twitterHandle}
              share_twitter_tags={keywords}
              share_title={title}
              share_url={url}
            />
            <div className="middle_content">
              <div className="mobile_view">
                <h2>{BlogPostData.htmlTitle}</h2>
              </div>
              {BlogVideosInfo[BlogPostData.slug] && (
                <div className="res_img" id="player">
                  <ReactJWPlayerContainer
                    file={`${cdnBaseUrl}${
                      BlogVideosInfo[BlogPostData.slug].file
                    }`}
                    image={BlogPostData.featuredImage}
                    videoSubtitlesEnabledLanguages={
                      videoSubtitlesEnabledLanguages
                    }
                    title={t(
                      "main:single-testimonial_captions_javelo-video_title",
                    )}
                    i18nLangCode={pageInfo.lang}
                    i18nLabels={{
                      languageNameLabel: t(
                        "video:global_controls_own-language-name_label",
                      ),
                      playLabel: t("video:global_controls_play_label"),
                      rewindLabel: t("video:global_controls_rewind_label"),
                      pipIconLabel: t("video:global_controls_pip-icon_label"),
                      unmuteLabel: t("video:global_controls_unmute_label"),
                      muteLabel: t("video:global_controls_mute_label"),
                      volumeLabel: t("video:global_controls_volume_label"),
                      volumeSliderLabel: t(
                        "video:global_controls_volume-slider_label",
                      ),
                      pauseLabel: t("video:global_controls_pause_label"),
                      settingsLabel: t("video:global_controls_settings_label"),
                      ccLabel: t("video:global_controls_cc_label"),
                      offLabel: t("video:global_controls_off_label"),
                      fullscreenLabel: t(
                        "video:global_controls_fullscreen_label",
                      ),
                      exitFullscreenLabel: t(
                        "video:global_controls_exit-fullscreen_label",
                      ),
                      resetLabel: t("video:global_controls_reset_label"),
                      captionsColorLabel: t(
                        "video:global_captions_color_label",
                      ),
                      captionsBgColorLabel: t(
                        "video:global_captions_bg-color_label",
                      ),
                      captionsBgOpacityLabel: t(
                        "video:global_captions_bg-opacity_label",
                      ),
                      captionsBlackLabel: t(
                        "video:global_captions_black_label",
                      ),
                      captionsBlueLabel: t("video:global_captions_blue_label"),
                      captionsCharacterEdgeLabel: t(
                        "video:global_captions_character-edge_label",
                      ),
                      captionsCyanLabel: t("video:global_captions_cyan_label"),
                      captionsDepressedLabel: t(
                        "video:global_captions_depressed_label",
                      ),
                      captionsDisabledLabel: t(
                        "video:global_captions_disabled_label",
                      ),
                      captionsEnabledLabel: t(
                        "video:global_captions_enabled_label",
                      ),
                      captionsDropShadowLabel: t(
                        "video:global_captions_drop-shadow_label",
                      ),
                      captionsFontFamilyLabel: t(
                        "video:global_captions_font-family_label",
                      ),
                      captionsFontOpacityLabel: t(
                        "video:global_captions_font-opacity_label",
                      ),
                      captionsFontSizeLabel: t(
                        "video:global_captions_font-size_label",
                      ),
                      captionsGreenLabel: t(
                        "video:global_captions_green_label",
                      ),
                      captionsMagentaLabel: t(
                        "video:global_captions_magenta_label",
                      ),
                      captionsNoneLabel: t("video:global_captions_none_label"),
                      captionsRaisedLabel: t(
                        "video:global_captions_raised_label",
                      ),
                      captionsRedLabel: t("video:global_captions_red_label"),
                      captionsResetLabel: t(
                        "video:global_captions_reset_label",
                      ),
                      captionsSubtitleSettingsLabel: t(
                        "video:global_captions_subtitle-settings_label",
                      ),
                      captionsUniformLabel: t(
                        "video:global_captions_uniform_label",
                      ),
                      captionsWhiteLabel: t(
                        "video:global_captions_white_label",
                      ),
                      captionsWindowColorLabel: t(
                        "video:global_captions_window-color_label",
                      ),
                      captionsWindowOpacityLabel: t(
                        "video:global_captions_window-opacity_label",
                      ),
                      captionsUserYellowLabel: t(
                        "video:global_captions_yellow_label",
                      ),
                      captionsUserFontScaleLabel: t(
                        "video:global_captions_user-font-scale_label",
                      ),
                      captionsEdgeStyleLabel: t(
                        "video:global_captions_edge-style_label",
                      ),
                    }}
                  />
                </div>
              )}

              <div
                dangerouslySetInnerHTML={{ __html: BlogPostData.postBody }}
              ></div>
            </div>
          </div>
        </div>
        <div className="blogpost-reference" id="clients">
          <ClientSlider />
        </div>
      </div>
      {/* main content end */}
    </Layout>
  );
};

export const query = graphql`
  query BlogPostQuery($origSlug: String!) {
    allArticlesJson(filter: { slug: { eq: $origSlug } }) {
      edges {
        node {
          id
          name
          language
          htmlTitle
          metaDescription
          slug
          featuredImage
          featuredImageAltText
          publishDate
          currentState
          contentGroupId
          tagIds
          postSummary
          postBody
        }
      }
    }
  }
`;

export default BlogPage;
