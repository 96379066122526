/* eslint-disable no-irregular-whitespace */

import * as React from "react";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Slider from "react-slick";
import i18n from "../components/i18n";
import { useTranslation } from "react-i18next";
import Layout from "../components/layout";
import NavigationHelper from "../helpers/navigation";
import Seo from "../components/seo";

/** Images import */
import img_video_img from "../images/upfactor_a1_cloud_testimonial_preview.webp";
import img_video_javelo from "../images/javelo_a1_cloud_testimonial_preview.webp";
import img_tpop_arrow_icon from "../images/a1_cloud_blue_quote_sign.webp";
import img_photo_man from "../images/thumb_video_upfactor.webp";
import img_thumb_javelo from "../images/thumb_video_javelo.webp";

// markup
const LegalPage = (props) => {
  const [activeLanguage, setActiveLanguage] = useState();
  useEffect(() => {
    setActiveLanguage(i18n.language);
  }, [setActiveLanguage]);

  const { t } = useTranslation(["main", "forms"]);

  // const langURLKey = `./`;
  // const servicesURLKey = `${langURLKey}services`;

  // Navigation info
  const pageInfo = {
    key: "legal",
    lang: i18n.language,
  };

  const testimonials_slider_settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
  };

  return (
    <Layout location={props.location} pageInfo={pageInfo}>
      <Helmet>
        <title>{t("main:title_legal")}</title>
      </Helmet>

      <Seo
        description={t("main:legal_meta-seo-description_txt")}
        title={t("main:title_legal")}
        keywords={[]}
        url={props.location.href}
        image_url={`${props.location.origin}/a1_cloud_logo.png`}
      />

      {/* breadcumbs */}
      <div className="site-breadcumbs">
        <div className="container">
          <small>
            <a
              href={NavigationHelper.useGeneratePageURL(activeLanguage, "home")}
              title={t("main:global_header_home_menu-item-seo-title_txt")}
            >
              A1:
            </a>{" "}
            // Mentions légales
          </small>
        </div>
      </div>
      {/* end breadcumbs */}
      {/* slider-banner */}

      <div className="service_detail service-page-main-content legal-page">
        <div className="container">
          <div className="middle_content">
            <h2>
              <span>Mentions légales</span>et politique de confidentialité
            </h2>
            <p>
              A1 Cloud Technologies, soucieuse des droits des individus,
              notamment au regard des traitements automatisés et dans une
              volonté de transparence avec ses clients, a mis en place une
              politique reprenant l’ensemble de ces traitements, des finalités
              poursuivies par ces derniers ainsi que des moyens d’actions à la
              disposition des individus afin qu’ils puissent au mieux exercer
              leurs droits.  Pour toute information complémentaire sur la
              protection des données personnelles, nous vous invitons à
              consulter le site : https://www.cnil.fr/
            </p>
            <p>
              La poursuite de la navigation sur ce site vaut acceptation sans
              réserve des dispositions et conditions d'utilisation qui suivent. 
            </p>
            <p>
              La version actuellement en ligne de ces conditions d'utilisation
              est la seule opposable pendant toute la durée d'utilisation du
              site et jusqu'à ce qu'une nouvelle version la remplace.
            </p>
            <h3>Article 1 - Mentions légales</h3>
            <h4>1.1 Site (ci-après « le site ») :</h4>
            <p>A1cloud.tech</p>
            <h4>1.2 Éditeur (ci-après « l'éditeur ») :</h4>
            <p>
              A1 Cloud Technologies SAS
              <br />
              Bureau 3, 9 rue de Condé 33000 Bordeaux FRANCE
            </p>
            <h4>Hébergeur (ci-après « l'hébergeur ») : </h4>
            <p>
              A1cloud.tech est hébergé par Google Cloud France, dont le siège
              social est situé 8 rue de Londres 75009 Paris 09.
            </p>
            <h4>1.4 Délégué à la protection des données (DPO) :</h4>
            <p>
              Un délégué à la protection des données : Marc-Antoine Adélise,
              dpo@a1cloud.tech, est à votre disposition pour toute question
              relative à la protection de vos données personnelles.
            </p>

            <h3>Article 2 - Accès au site</h3>
            <p>
              L'accès au site et son utilisation sont réservés à un usage
              strictement personnel. Vous vous engagez à ne pas utiliser ce site
              et les informations ou données qui y figurent à des fins
              commerciales, politiques, publicitaires et pour toute forme de
              sollicitation commerciale et notamment l'envoi de courriers
              électroniques non sollicités.
            </p>
            <h3>Article 3 - Contenu du site</h3>
            <p>
              Toutes les marques, photographies, textes, commentaires,
              illustrations, images animées ou non, séquences vidéo, sons, ainsi
              que toutes les applications informatiques qui pourraient être
              utilisées pour faire fonctionner ce site et plus généralement tous
              les éléments reproduits ou utilisés sur le site sont protégés par
              les lois en vigueur au titre de la propriété intellectuelle. Ils
              sont la propriété pleine et entière de l'éditeur ou de ses
              partenaires. Toute reproduction, représentation, utilisation ou
              adaptation, sous quelque forme que ce soit, de tout ou partie de
              ces éléments, y compris les applications informatiques, sans
              l'accord préalable et écrit de l'éditeur, sont strictement
              interdites. Le fait pour l'éditeur de ne pas engager de procédure
              dès la prise de connaissance de ces utilisations non autorisées ne
              vaut pas acceptation desdites utilisations et renonciation aux
              poursuites.
            </p>
            <h3>Article 4 - Gestion du site</h3>
            <p>
              Pour la bonne gestion du site, l'éditeur pourra à tout moment :
              <ul>
                <li>
                  <strong>.</strong>suspendre, interrompre ou limiter l'accès à
                  tout ou partie du site, réserver l'accès au site, ou à
                  certaines parties du site, à une catégorie déterminée
                  d'internautes ;
                </li>
                <li>
                  <strong>.</strong>supprimer toute information pouvant en
                  perturber le fonctionnement ou entrant en contravention avec
                  les lois nationales ou internationales ;
                </li>
                <li>
                  <strong>.</strong>suspendre le site afin de procéder à des
                  mises à jour.
                </li>
              </ul>
            </p>
            <h3>Article 5 - Responsabilités</h3>
            <p>
              La responsabilité de l'éditeur ne peut être engagée en cas de
              défaillance, panne, difficulté ou interruption de fonctionnement,
              empêchant l'accès au site ou à une de ses fonctionnalités.
            </p>
            <p>
              Le matériel de connexion au site que vous utilisez est sous votre
              entière responsabilité. Vous devez prendre toutes les mesures
              appropriées pour protéger votre matériel et vos propres données
              notamment d'attaques virales par Internet. Vous êtes par
              ailleurs seul responsable des sites et données que vous consultez.
            </p>
            <p>
              L'éditeur ne pourra être tenu responsable en cas de poursuites
              judiciaires à votre encontre :
              <ul>
                <li>
                  <strong>.</strong>du fait de l'usage du site ou de tout
                  service accessible via Internet ;
                </li>
                <li>
                  <strong>.</strong>du fait du non-respect par vous des
                  présentes conditions générales.
                </li>
              </ul>
            </p>
            <p>
              L'éditeur n'est pas responsable des dommages causés à vous-même, à
              des tiers et/ou à votre équipement du fait de votre connexion ou
              de votre utilisation du site et vous renoncez à toute action
              contre lui de ce fait.
            </p>
            <p>
              Si l'éditeur venait à faire l'objet d'une procédure amiable ou
              judiciaire en raison de votre utilisation du site, il pourra se
              retourner contre vous pour obtenir l'indemnisation de tous les
              préjudices, sommes, condamnations et frais qui pourraient découler
              de cette procédure.
            </p>
            <h3>Article 6 - Liens hypertextes</h3>
            <p>
              La mise en place par les utilisateurs de tous liens hypertextes
              vers tout ou partie du site est strictement interdite, sauf
              autorisation préalable et écrite de l'éditeur.
            </p>
            <p>
              L'éditeur est libre de refuser cette autorisation sans avoir à
              justifier de quelque manière que ce soit sa décision. Dans le cas
              où l'éditeur accorderait son autorisation, celle-ci n'est dans
              tous les cas que temporaire et pourra être retirée à tout moment,
              sans obligation de justification à la charge de l'éditeur.
            </p>
            <p>
              Toute information accessible via un lien vers d'autres sites n'est
              pas publiée par l'éditeur. L'éditeur ne dispose d'aucun droit sur
              le contenu présent dans ledit lien.
            </p>
            <h3>Article 7 - Collecte et protection des données</h3>
            <p>Vos données sont collectées par A1 Cloud Technologies.</p>
            <p>
              Une donnée à caractère personnel désigne toute information
              concernant une personne physique identifiée ou identifiable
              (personne concernée) ; est réputée identifiable une personne qui
              peut être identifiée, directement ou indirectement, notamment par
              référence à un nom, un numéro d'identification ou à un ou
              plusieurs éléments spécifiques, propres à son identité physique,
              physiologique, génétique, psychique, économique, culturelle ou
              sociale.
            </p>
            <p>
              Les informations personnelles pouvant être recueillies sur le site
              sont principalement utilisées par l'éditeur pour la gestion des
              relations avec vous, et le cas échéant pour le traitement de vos
              commandes. 
            </p>
            <p>
              Les données personnelles collectées sont les suivantes :
              <ul>
                <li>
                  <strong>.</strong>nom et prénom
                </li>
                <li>
                  <strong>.</strong>adresse mail
                </li>
                <li>
                  <strong>.</strong>numéro de téléphone
                </li>
              </ul>
            </p>
            <p>
              Un délégué à la protection des données : Marc-Antoine
              Adélise, marc.adelise@a1cloud.tech, est à votre disposition pour
              toute question relative à la protection de vos données
              personnelles.
            </p>
            <h3>
              Article 8 - Droit d’accès, de rectification et de déréférencement
              de vos données
            </h3>
            <p>
              En application de la réglementation applicable aux données à
              caractère personnel, les utilisateurs disposent des droits
              suivants :
              <ul>
                <li>
                  <strong>.</strong>le droit d’accès : ils peuvent exercer leur
                  droit d'accès, pour connaître les données personnelles les
                  concernant, en écrivant à l'adresse électronique ci-dessous
                  mentionnée. Dans ce cas, avant la mise en œuvre de ce droit,
                  la Plateforme peut demander une preuve de l'identité de
                  l'utilisateur afin d'en vérifier l'exactitude ;
                </li>
                <li>
                  <strong>.</strong>le droit de rectification : si les données à
                  caractère personnel détenues par la Plateforme sont inexactes,
                  ils peuvent demander la mise à jour des informations ;
                </li>
                <li>
                  <strong>.</strong>le droit de suppression des données : les
                  utilisateurs peuvent demander la suppression de leurs données
                  à caractère personnel, conformément aux lois applicables en
                  matière de protection des données ;
                </li>
                <li>
                  <strong>.</strong>le droit à la limitation du traitement : les
                  utilisateurs peuvent de demander à la Plateforme de limiter le
                  traitement des données personnelles conformément aux
                  hypothèses prévues par le RGPD ;
                </li>
                <li>
                  <strong>.</strong>le droit de s’opposer au traitement des
                  données : les utilisateurs peuvent s’opposer à ce que
                  leurs données soient traitées conformément aux hypothèses
                  prévues par le RGPD ;
                </li>
                <li>
                  <strong>.</strong>le droit à la portabilité : ils peuvent
                  réclamer que la Plateforme leur remette les données
                  personnelles qu'ils ont fournies pour les transmettre à une
                  nouvelle Plateforme.
                </li>
              </ul>
            </p>
            <p>
              Vous pouvez exercer ce droit en nous contactant, à l’adresse
              suivante :
            </p>
            <p>
              A1 Cloud Technologies SAS
              <br />
              Bureau 3
              <br />9 rue de Condé
              <br />
              33000 Bordeaux
              <br />
              FRANCE
            </p>
            <p>Ou par email, à l’adresse : legal@a1cloud.tech</p>
            <p>
              Vous pouvez aussi vous adresser à notre délégué à la protection
              des données : Marc-Antoine Adélise, marc.adelise@a1cloud.tech, qui
              est à votre disposition pour toute question relative à la
              protection de vos données personnelles.
            </p>
            <p>
              Toute demande doit être accompagnée de la photocopie d’un titre
              d’identité en cours de validité signé et faire mention de
              l’adresse à laquelle l'éditeur pourra contacter le demandeur. La
              réponse sera adressée dans le mois suivant la réception de la
              demande. Ce délai d'un mois peut être prolongé de deux mois si la
              complexité de la demande et/ou le nombre de demandes l'exigent.
            </p>
            <p>
              De plus, et depuis la loi n°2016-1321 du 7 octobre 2016, les
              personnes qui le souhaitent, ont la possibilité d’organiser le
              sort de leurs données après leur décès. Pour plus d’information
              sur le sujet, vous pouvez consulter le site Internet de la
              CNIL : https://www.cnil.fr/.
            </p>
            <p>
              Les utilisateurs peuvent aussi introduire une réclamation auprès
              de la CNIL sur le site de la CNIL : https://www.cnil.fr. 
            </p>
            <p>
              Nous vous recommandons de nous contacter dans un premier
              temps avant de déposer une réclamation auprès de la CNIL, car nous
              sommes à votre entière disposition pour régler votre problème.
            </p>
            <h3>Article 9 - Utilisation des données</h3>
            <p>
              Les données personnelles collectées auprès des utilisateurs ont
              pour objectif la mise à disposition des services de la Plateforme,
              leur amélioration et le maintien d'un environnement sécurisé. La
              base légale des traitements est  l’exécution du contrat entre
              l’utilisateur et la Plateforme. Plus précisément, les utilisations
              sont les suivantes :
            </p>
            <p>
              <ul>
                <li>
                  <strong>.</strong>accès et utilisation de la Plateforme par
                  l'utilisateur ;
                </li>
                <li>
                  <strong>.</strong>gestion du fonctionnement et optimisation de
                  la Plateforme ;
                </li>
                <li>
                  <strong>.</strong>mise en œuvre d'une assistance
                  utilisateurs ;
                </li>
                <li>
                  <strong>.</strong>vérification, identification et
                  authentification des données transmises par l'utilisateur ;
                </li>
                <li>
                  <strong>.</strong>personnalisation des services en affichant
                  des publicités en fonction de l'historique de navigation de
                  l'utilisateur, selon ses préférences ;
                </li>
                <li>
                  <strong>.</strong>prévention et détection des fraudes,
                  malwares (malicious softwares ou logiciels malveillants) et
                  gestion des incidents de sécurité ;
                </li>
                <li>
                  <strong>.</strong>gestion des éventuels litiges avec les
                  utilisateurs ;
                </li>
                <li>
                  <strong>.</strong>envoi d'informations commerciales et
                  publicitaires, en fonction des préférences de l'utilisateur ;
                </li>
              </ul>
            </p>
            <h3>Article 10 - Politique de conservation des données</h3>
            <p>
              La Plateforme conserve vos données pour la durée nécessaire pour
              vous fournir ses services ou son assistance.
            </p>
            <p>
              Dans la mesure raisonnablement nécessaire ou requise pour
              satisfaire aux obligations légales ou réglementaires, régler des
              litiges, empêcher les fraudes et abus ou appliquer nos modalités
              et conditions, nous pouvons également conserver certaines de vos
              informations si nécessaire, même après que vous ayez fermé votre
              compte ou que nous n'ayons plus besoin pour vous fournir
              nos services.
            </p>
            <h3>Article 11- Partage des données personnelles avec des tiers</h3>
            <p>
              Les données personnelles peuvent être partagées avec des sociétés
              tierces exclusivement dans l’Union européenne, dans les cas
              suivants :
            </p>
            <p>
              <ul>
                <li>
                  <strong>.</strong>lorsque l'utilisateur publie, dans les zones
                  de commentaires libres de la Plateforme, des informations
                  accessibles au public ;
                </li>
                <li>
                  <strong>.</strong>quand l'utilisateur autorise le site web
                  d'un tiers à accéder à ses données ;
                </li>
                <li>
                  <strong>.</strong>quand la Plateforme recourt aux services de
                  prestataires pour fournir l'assistance utilisateurs, la
                  publicité et les services de paiement. Ces prestataires
                  disposent d'un accès limité aux données de l'utilisateur, dans
                  le cadre de l'exécution de ces prestations, et ont
                  l'obligation contractuelle de les utiliser en conformité avec
                  les dispositions de la réglementation applicable en matière
                  protection des données à caractère personnel ;
                </li>
                <li>
                  <strong>.</strong>si la loi l'exige, la Plateforme peut
                  effectuer la transmission de données pour donner suite aux
                  réclamations présentées contre la Plateforme et se conformer
                  aux procédures administratives et judiciaires.
                </li>
              </ul>
            </p>
            <h3>Article 12 - Offres commerciales</h3>
            <p>
              Vous êtes susceptible de recevoir des offres commerciales de
              l'éditeur. Si vous ne le souhaitez pas, veuillez cliquer sur le
              lien suivant : hello@a1cloud.tech.
            </p>
            <p>
              Vos données sont susceptibles d’être utilisées par les partenaires
              de l'éditeur à des fins de prospection commerciale, si vous ne le
              souhaitez pas, veuillez cliquer sur le lien
              suivant : hello@a1cloud.tech.
            </p>
            <p>
              Si, lors de la consultation du site, vous accédez à des données à
              caractère personnel, vous devez vous abstenir de toute collecte,
              de toute utilisation non autorisée et de tout acte pouvant
              constituer une atteinte à la vie privée ou à la réputation des
              personnes. L'éditeur décline toute responsabilité à cet égard.
            </p>
            <p>
              Les données sont conservées et utilisées pour une durée conforme à
              la législation en vigueur.
            </p>
            <h3>Article 13 - Cookies</h3>
            <p>Qu’est-ce qu’un « cookie » ?</p>
            <p>
              Un « Cookie » ou traceur est un fichier électronique déposé sur un
              terminal (ordinateur, tablette, smartphone,…) et lu par exemple
              lors de la consultation d'un site internet, de la lecture d'un
              courrier électronique, de l'installation ou de l'utilisation d'un
              logiciel ou d'une application mobile et ce, quel que soit le type
              de terminal
              utilisé (source : https://www.cnil.fr/fr/cookies-traceurs-que-dit-la-loi).
            </p>
            <p>
              Le site peut collecter automatiquement des informations standards.
              Toutes les informations collectées indirectement ne seront
              utilisées que pour suivre le volume, le type et la configuration
              du trafic utilisant ce site, pour en développer la conception et
              l'agencement et à d'autres fins administratives et de
              planification et plus généralement pour améliorer le service que
              nous vous offrons.
            </p>
            <p>
              Le cas échéant, des « cookies » émanant de l'éditeur du site et/ou
              des sociétés tiers pourront être déposés sur votre terminal, avec
              votre accord. Dans ce cas, lors de la première navigation sur ce
              site, une bannière explicative sur l’utilisation des « cookies »
              apparaîtra. Avant de poursuivre la navigation, le client et/ou le
              prospect devra accepter ou refuser l’utilisation desdits
              « cookies ». Le consentement donné sera valable pour une période
              de treize (13) mois. L'utilisateur a la possibilité de désactiver
              les cookies à tout moment.
            </p>
            <p>Les cookies suivants sont présents sur ce site :</p>
            <p>La durée de vie de ces cookies est de treize mois.</p>
            <h3>Article 14 - Photographies et représentation des produits</h3>
            <p>
              Les photographies de produits, accompagnant leur description, ne
              sont pas contractuelles et n'engagent pas l'éditeur.
            </p>
            <h3>Article 15 - Loi applicable</h3>
            <p>
              Les présentes conditions d'utilisation du site sont régies par la
              loi française et soumises à la compétence des tribunaux du siège
              social de l'éditeur, sous réserve d'une attribution de compétence
              spécifique découlant d'un texte de loi ou réglementaire
              particulier.
            </p>
            <h3>Article 16 - Contactez-nous</h3>
            <p>
              Pour toute question, information sur les produits présentés sur le
              site, ou concernant le site lui-même, vous pouvez laisser un
              message à l'adresse suivante : legal@a1cloud.tech .
            </p>
          </div>
        </div>
      </div>
      {/* testimonial section  */}
      <div className="service-page testimonial_sec">
        <div className="container">
          <h1 className="sec-title"> {t("main:title_your_testimonials")} </h1>
          <div className="testi-slider">
            <div className="home-testimonial-slider responsive slick-initialized slick-slider slick-dotted">
              <Slider {...testimonials_slider_settings}>
                <div className="slider-block">
                  <div className="img-box">
                    <div className="res_img">
                      <a
                        href={NavigationHelper.useGeneratePageURL(
                          activeLanguage,
                          "client_testimonial",
                          "upfactor",
                        )}
                        title={t(
                          "main:single-testimonial_see-video-seo-link_txt",
                          { clientname: "UpFactor" },
                        )}
                      >
                        <img
                          src={img_video_img}
                          alt={t("main:testimonials_main_video-image-alt_txt", {
                            clientname: "UpFactor",
                          })}
                        />
                        <div className="play-icon">
                          <i className="fa fa-play" aria-hidden="true" />
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className="text-block">
                    <div className="text-block-img">
                      <span className="quot_img">
                        <img src={img_tpop_arrow_icon} alt="quote image" />
                      </span>
                      <div className="user_img">
                        <img
                          className="thumbnail_img"
                          src={img_photo_man}
                          alt={t("main:global_person_photo-alt_txt", {
                            name: "Didier Mignery",
                          })}
                        />
                      </div>
                    </div>
                    <div className="text-block-right-text">
                      <h2 className="desktop_view">
                        <q>
                          {t("home_testimonials_upfactor_p1")}
                          <br /> {t("main:home_testimonials_upfactor_p2")}
                          <br /> {t("main:home_testimonials_upfactor_p3")}
                        </q>
                      </h2>
                      <h2 className="mobile_view">
                        <q>
                          {t("home_testimonials_upfactor_p1")}
                          <br /> {t("main:home_testimonials_upfactor_p2")}
                          <br /> {t("main:home_testimonials_upfactor_p3")}
                          {/* <br /> des bâtiments… */}
                        </q>
                      </h2>
                      <div className="user_designation">
                        <h5>
                          {t(
                            "main:single-testimonial_main_upfactor-interviewee-name_txt",
                          )}
                        </h5>
                        <span>
                          {t(
                            "main:single-testimonial_main_upfactor-interviewee-position_txt",
                          )}
                        </span>
                      </div>
                      <a
                        href={NavigationHelper.useGeneratePageURL(
                          activeLanguage,
                          "client_testimonial",
                          "upfactor",
                        )}
                        title={t(
                          "main:single-testimonial_see-video-seo-link_txt",
                          { clientname: "UpFactor" },
                        )}
                      >
                        {t("main:global_testimonials_see_btn")}
                      </a>
                    </div>
                  </div>
                </div>
                <div className="slider-block">
                  <div className="img-box">
                    <div className="res_img">
                      <a
                        href={NavigationHelper.useGeneratePageURL(
                          activeLanguage,
                          "client_testimonial",
                          "javelo",
                        )}
                        title={t(
                          "main:single-testimonial_see-video-seo-link_txt",
                          { clientname: "Javelo" },
                        )}
                      >
                        <img
                          src={img_video_javelo}
                          alt={t("main:testimonials_main_video-image-alt_txt", {
                            clientname: "Javelo",
                          })}
                        />
                        <div className="play-icon">
                          <i className="fa fa-play" aria-hidden="true" />
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className="text-block">
                    <div className="text-block-img">
                      <span className="quot_img">
                        <img src={img_tpop_arrow_icon} alt="quote image" />
                      </span>
                      <div className="user_img">
                        <img
                          className="thumbnail_img"
                          src={img_thumb_javelo}
                          alt={t("main:global_person_photo-alt_txt", {
                            name: "Bakari Sumaila",
                          })}
                        />
                      </div>
                    </div>
                    <div className="text-block-right-text">
                      <h2 className="desktop_view">
                        <q>
                          {t("main:home_testimonials_javelo_p1")} <br />
                          {t("main:home_testimonials_javelo_p2")} <br />
                          {t("main:home_testimonials_javelo_p3")} <br />
                          {t("main:home_testimonials_javelo_p4")} <br />
                          {t("main:home_testimonials_javelo_p5")}
                        </q>
                      </h2>
                      <h2 className="mobile_view">
                        <q>
                          {t("main:home_testimonials_javelo_p1")} <br />
                          {t("main:home_testimonials_javelo_p2")} <br />
                          {t("main:home_testimonials_javelo_p3")} <br />
                          {t("main:home_testimonials_javelo_p4")} <br />
                          {t("main:home_testimonials_javelo_p5")}
                        </q>
                      </h2>
                      <div className="user_designation">
                        <h5>
                          {t(
                            "main:single-testimonial_main_javelo-interviewee-name_txt",
                          )}
                        </h5>
                        <span>
                          {t(
                            "main:single-testimonial_main_javelo-interviewee-position_txt",
                          )}
                        </span>
                      </div>
                      <a
                        href={NavigationHelper.useGeneratePageURL(
                          activeLanguage,
                          "client_testimonial",
                          "javelo",
                        )}
                        title={t(
                          "main:single-testimonial_see-video-seo-link_txt",
                          { clientname: "Javelo" },
                        )}
                      >
                        {t("main:global_testimonials_see_btn")}
                      </a>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
            <div className="text-center">
              <a
                href={NavigationHelper.useGeneratePageURL(
                  activeLanguage,
                  "testimonials",
                )}
                className="btn btn-blue"
                title={t("main:global_testimonials_see-all-seo-title_txt")}
              >
                {t("main:global_testimonials_see-all_btn")}
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* main content end */}
    </Layout>
  );
};

export default LegalPage;
